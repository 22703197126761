import styles from "./styles/posts.module.css"
import SearchBar from "./components/SearcBar";
import React, { useEffect, useState } from "react";
import DashTopNav from "../../../components/DashTopNav/DashTopNav";
import LeftBarDatas3 from "../../../components/DashSideCards/LeftBarData3/LeftBarData3";
import '../../../styles/dashboard/posts.scss'
import comment from "../../../assets/icons/comment-light.svg";
import like from "../../../assets/icons/like-light.svg";
import music from "../../../assets/icons/music-note.svg";
import playlist from "../../../assets/icons/playlist.svg";
import muzik from "../../../assets/icons/muzik.svg";
import img from "../../../assets/icons/img.svg";
import play from "../../../assets/icons/play.svg";
import certified from "../../../assets/icons/certified.svg";
import playlistBG from "../../../assets/img/playlist.png";
import musicBG from "../../../assets/img/music.png";
import art from "../../../assets/img/artist.png";
import axios from "axios";
import { TestEnvAPI } from "../../../envs";
import useUser from "../../../hooks/useUser";
import { SpinnerCircular } from "spinners-react";
export default function Posts() {
  const [isActive, setIsActive] = useState('media')
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState('media')

  const token = localStorage.getItem("access_token")
  // const user = useUser();
  // console.log(access_token)

  const media = [
    {
      type: "none",
      likes: '3202',
      comments: '854'
    },
    {
      type: "music",
      likes: '3202',
      comments: '854'
    },
    {
      type: "none",
      likes: '3202',
      comments: '854'
    },
    {
      type: "playlist",
      likes: '3202',
      comments: '854',
      songs: 12
    },
    {
      type: "none",
      likes: '3202',
      comments: '854'
    },
    {
      type: "none",
      likes: '3202',
      comments: '854'
    },
    {
      type: "none",
      likes: '3202',
      comments: '854'
    },
    {
      type: "none",
      likes: '3202',
      comments: '854'
    },
    {
      type: "none",
      likes: '3202',
      comments: '854'
    },
  ]

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data: response } = await axios.get(
        `${TestEnvAPI}media-api/users/stories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      setData(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  useEffect(() => {
    fetchData()
  }, [])

  return (

    // <div className="h-screen w-screen flex flex-col items-center justify-center text-white text-2xl font-mono">
    //   <p>In development <span className="animate-spin spinner">⚙️</span>...</p>
    //   <p>Available soon</p>
    // </div>
    <div className="posts main-dash">

      <DashTopNav />
      <div className={styles.searchcontainer}>
        <SearchBar searchPlaceholder={"Search a Post"} />
      </div>

      <div className="datas">
        <div className="main-side mt-28">
          <h1 className="text-white font-bold text-4xl leading-10 text-start">Vos publications dans l’actualité </h1>


          <div className="mobile-left-bar-data-3">
            <LeftBarDatas3 />
          </div>

          <div className="toggler">
            <h2 className={isActive === 'media' ? 'isActive' : ''} onClick={() => setIsActive('media')}>Media</h2>
            {/* <h2 className={isActive === 'posts' ? 'isActive' : ''} onClick={() => setIsActive('posts')}>Post</h2> */}
          </div>

          <section className="main-body">

            {
              data ?
                data.results.map((item, index) => (
                  <div className="card" key={index} style={item.type === 'ALBUM' ? { background: `url(${item.album.cover}) center/cover no-repeat` } : item.type === 'AUDIO' ? { background: `url(${musicBG}) center/cover no-repeat` } : item.type === 'STORY' ? { background: `url(${item.cover}) center/cover no-repeat` } : { background: `url(${item.cover}) center/cover no-repeat` }}>

                    <div className="top">
                      <div className="type">
                        <p>{item.type}</p>
                      </div>
                      
                          {/* <div >
                            <p>12</p>
                            <img src={music} alt="music" />
                          </div> */}
                    </div>
                    {
                      item.type === 'ALBUM' &&
                      <aside style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                        <div className="bottom">
                          <div>
                            <img src={like} alt="like" />
                            <p>{item.nb_of_likers}</p>
                          </div>
                          <div>
                            <img src={comment} alt="comment" />
                            <p>{item.comments.length}</p>
                          </div>
                        </div>

                      </aside>
                    }
                    {
                      item.type === 'AUDIO' &&
                      <aside>
                        <div className="middle flex">
                          <img src={item.audio.cover} alt="artist" width={48} height={48} className="rounded-lg" />
                          <div className="flex flex-1 flex-col items-start justify-center">
                            <p>{item.audio.title} </p>
                            <p className="flex certif" >{item.author.alias} <img src={certified} alt="certified" className="ml-1" /></p>
                          </div>
                          <img src={play} alt="play" />
                        </div>
                        <div className="bottom">
                          <div>
                            <img src={like} alt="like" />
                            <p>{item.nb_of_likers}</p>
                          </div>
                          <div>
                            <img src={comment} alt="comment" />
                            <p>{item.comments.length}</p>
                          </div>
                        </div>
                      </aside>

                    }
                    {
                      item.type === 'none' &&
                      <img src={img} alt="img" />
                    }
                    {
                      item.type !== 'ALBUM' && item.type !== 'AUDIO' &&

                      <div className="bottom">
                        <div>
                          <img src={like} alt="like" />
                          <p>{item.nb_of_likers}</p>
                        </div>
                        <div>
                          <img src={comment} alt="comment" />
                          <p>{item.comments.length}</p>
                        </div>
                      </div>
                    }
                  </div>
                ))
                :
                <SpinnerCircular color="#FF5620" />
            }

          </section>
        </div>
      </div >
      {/* <SwitchUnderlinedProvider>
        <MediaPostSwitch />
      </SwitchUnderlinedProvider> */}
    </div >
  );
}