import { BaseApi } from "../index";

export default class PostAPI extends BaseApi{

  constructor(token=null) {
    super();

    this.token = token
  }

  async createPost(payload){
    try {
      const response = await this.insertToken(this.token).post(
        "/media-api/stories/",
        payload
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async getPosts(){
    try {
      const response = await this.insertToken(this.token).get(
        "/media-api/users/stories/"
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async getUserPosts(userID){
    try {
      const response = await this.insertToken(this.token).get(
        `media-api/users/${userID}/stories`
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async getAllPostComments(postID){
    try{
      const response = await this.insertToken(this.token).get(
        `media-api/stories/${postID}/comments/`
      );
      return {
        data: response.data
      }
    }catch (err){
      return {
        error: err
      }
    }
  }

  async answerPostComment(commentID, payload){
    try{
      const response = await this.insertToken(this.token).post(
        `media-api/comments/${commentID}/replies/`,
        payload
      )

      return {
        data: null
      }
    }catch (err){
      return {
        error: err
      }
    }
  }

  // media-api/users/stories => connected user storry
  // POST: media-api/comment-stories : add a comment to a story
  // GET: media-api/report-stories : report a story
  // GET: media-api/stories/ id /like : like a story or unlike it if already liked
  // GET: media-api/stories/ id /dislike : dislike a story or undislike it if already disliked
  // GET: media-api/comment-stories/ id /like : like a story comment or unlike it if already liked
  // GET: media-api/comment-stories/ id /dislike : dislike a story comment or undislike it if already disliked

}